import React, { useRef, useState, useEffect } from "react";
import "./topUpModal.css";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Select from "react-select";
import plus from "../../../assets/plus.png";
import minus from "../../../assets/minus.png";
import _ from "lodash";
import { toast } from "react-toastify";
import {
  addNewCompany,
  fetchAddressFromZipCode,
  getAllCountries,
  getAllSellers,
  getSellersforMultipleCompany,
  getStates,
} from "../../../store/actions";
import {
  checkPermission,
  handleInputValueTypes,
  validateEmail,
  validatePhoneNumber,
} from "../../../config/utils";
import { accountsManagement, apiUrl, featureModule, featureSubmodulePermission, inventoryManagement, permissionAssociatedWarehouse, SUBF_SELLER, zipCodeRegex } from "../../../constant";
import Input from "react-phone-number-input/input";
import axios from "axios";
import { getWarehouseListing } from "../../../store/actions/inventory.action";

/* istanbul ignore next */
function AddCompanyModal(props) {
  const { showModal = true } = props;
  const modalRef = useRef();
  const employeePermissions = useSelector((state) => state?.sidebar?.employeePermissions);
  const { warehouseList } = useSelector((state) => state.InventoryManagement);
  const dispatch = useDispatch();
  const [errors, setErrors] = useState({});
  const [countryList, setcountryList] = useState();
  const createdBy = useSelector((state) => state.auth.user);
  const [stateList, setstateList] = useState();
  const countries = useSelector((state) => state.shipment.countries);
  const USSates = useSelector((state) => state.shipment.states);
  const [defaultAddressIndex, setDefaultAddressIndex] = useState(-1);
  const [formData, setFormData] = useState({
    createdDate: new Date(),
    isActive: true,
    modifiedBy: createdBy?.Value?.employeeId,
    createdBy: createdBy?.Value?.employeeId,
    modifiedDate: new Date(),
    isInventoryManagementAccess: true,
    addressModels: [
      {
        state: "",
        zipCode: "",
        city: "",
        isDefault: 0,
        country_Id: 218,
        country: "United States",
        phoneNumber: "+1",
        secondaryPhoneNumber: "+1",
        addressFirst: "",
        addressSecond: "",
        addressThird: "",
      },
    ],
  });

  useEffect(() => {
    dispatch(getAllCountries());
    dispatch(getStates(218));
    if (!warehouseList?.length) {
      fetchWareHouses();
    }
  }, []);

  useEffect(() => {
    if (props?.companyId) {
      setFormData({ ...formData, companyIds: props?.companyId });
      setErrors({
        ...errors,
        companyIds: "",
      });
    }
  }, [props?.companyId]);

  const fetchWareHouses = () => {
    let data = { "sortOrder": "ASCENDING" }
    dispatch(getWarehouseListing(data));
  }

  const addAddress = () => {
    const newAddresses = [
      ...formData.addressModels,
      {
        state: "",
        zipCode: "",
        city: "",
        isDefault: 0, // Set the new address's isDefault to 0 explicitly
        country_Id: 218,
        country: "United States",
        addressFirst: "",
        addressSecond: "",
        addressThird: "",
        phoneNumber: "+1",
        secondaryPhoneNumber: "+1"
      },
    ];
    setFormData({ ...formData, addressModels: newAddresses });
  };

  const removeAddress = (index) => {
    if (formData.addressModels.length > 1) {
      const newAddresses = [...formData.addressModels];
      // Check if the address being removed is the default address
      const defaultAddressRemoved = defaultAddressIndex === index;
      newAddresses.splice(index, 1);
      setFormData({ ...formData, addressModels: newAddresses });

      // Update defaultAddressIndex if the default address was removed
      if (defaultAddressRemoved) {
        setDefaultAddressIndex(-1); // Set defaultAddressIndex to -1 since the default address was removed
      }
    }
  };

  const handleChange = (index, field, value) => {
    const newErrors = { ...errors };
    if (newErrors[`addressModels_${index}`]) {
      delete newErrors[`addressModels_${index}`][field];
    }
    const newAddresses = [...formData.addressModels];

    if (field === "country_Id") {
      let allCountries = [...countries];
      let countryIndex = allCountries.findIndex(
        (item) => item.id === value.value
      );
      newAddresses[index].phoneNumber = `+(${countries[countryIndex].phoneCountryCode}) `;
      newAddresses[index].secondaryPhoneNumber = `+(${countries[countryIndex].phoneCountryCode}) `;
      newAddresses[index].country = value.label;
      newAddresses[index][field] = value.value;
    } else {
      newAddresses[index][field] = value;
    }

    setErrors(newErrors);
    setFormData({ ...formData, addressModels: newAddresses });
  };

  const TopUpForm = (item, name, sellerdetail) => {
    setFormData({ ...formData, [name]: item });
    setErrors({
      ...errors,
      [name]: "",
    });
  };

  useEffect(() => {
    if (countries && countries?.length) {
      let data = countries.map((item) => {
        return {
          label: item.name,
          value: item.id,
        };
      });
      setcountryList(data);
    } else {
      setcountryList([]);
    }
  }, [countries]);

  useEffect(() => {
    if (USSates && USSates?.length) {
      let data = USSates.map((item) => {
        return {
          label: item.StateName,
          value: item.StateName,
        };
      });
      setstateList(data);
    } else {
      setstateList([]);
    }
  }, [USSates]);

  const handleCompanySelected = (data, key) => {
    setFormData({ ...formData, [key]: _.map(data, "value").join(",") });
    setErrors({
      ...errors,
      [key]: "",
    });
  };

  const handleAsyncCall = async (isValid, newErrors) => {
    if (isValid && props?.type === "seller") {
      await handleSellerCall();
    } else if (isValid) {
      await handleCompanyCall();
    } else {
      setErrors(newErrors);
    }
  };

  const handleSellerCall = async () => {
    try {
      const config = {
        headers: { Authorization: `` },
      };
      formData.newSellerName = formData.sellerName;
      const response = await axios.post(
        `${apiUrl}/api/InsertSellerDetails`,
        formData,
        config
      );
      if (response?.data?.Status === 200) {
        if (props?.companyId) {
          props?.onHide && props?.onHide();
          dispatch(getSellersforMultipleCompany([props?.companyId]));
          toast.success(response?.data?.Message?.AppStatusDescription);
        } else {
          props?.onHide && props?.onHide();
          dispatch(
            getAllSellers(
              props?.setTotalPages,
              props?.numberOfPage,
              props?.currentPage,
              props?.filterData
            )
          );
          toast.success(response?.data?.Message?.AppStatusDescription);
        }
      } else {
        toast.error(response?.data?.Message?.AppStatusDescription);
      }
    } catch (e) {
      toast.error(e?.response?.data?.errorDescription);
    }
  };

  const handleCompanyCall = async () => {

    const res = await Promise.resolve(dispatch(addNewCompany(formData)));
    if (res.data.Status === 200) {
      handleCancel();
    }
  };

  const validateSellerDetails = (isValid, newErrors) => {
    if (!formData.sellerName) {
      isValid = false;
      newErrors.sellerName = "Seller Name is required";
    }
    if (!formData.sellerCode) {
      isValid = false;
      newErrors.sellerCode = "Seller Code is required";
    }
    if (!formData.companyIds) {
      isValid = false;
      newErrors.companyIds = "Company Ids is required";
    }
    if (!formData.email) {
      isValid = false;
      newErrors.email = "Email is required";
    }
    if (formData.secondaryEmailAddress && !validateEmail(formData.secondaryEmailAddress)) {
      isValid = false;
      newErrors.secondaryEmailAddress = "Invalid Email address";
    }
    return { isValid, newErrors };
  };

  const saveTopUpData = async () => {
    let hasDefaultAddress = false;
    let isValid = true;
    let newErrors = {};
    // Email validation regular expression
    if (props.type === "seller") {
      let sellerData = validateSellerDetails(isValid, newErrors);
      isValid = sellerData.isValid;
      newErrors = { ...newErrors, ...sellerData.newErrors };
    } else {
      if (!formData.name) {
        isValid = false;
        newErrors.name = "Company Name is required";
      }
      if (!formData.email) {
        isValid = false;
        newErrors.email = "Email is required";
      }
    }

    if (!validateEmail(formData.email)) {
      isValid = false;
      newErrors.email = "Invalid Email address";
    }

    if (formData.secondaryEmailAddress && !validateEmail(formData.secondaryEmailAddress)) {
      isValid = false;
      newErrors.secondaryEmailAddress = "Invalid Email address";
    }

    if (checkPermission(accountsManagement, SUBF_SELLER, permissionAssociatedWarehouse, featureSubmodulePermission, employeePermissions) && !formData.warehouseIds) {
      isValid = false;
      newErrors.warehouseIds = "Warehouse is required";
    }

    let dataValidation = validateAddresses(
      isValid,
      hasDefaultAddress,
      newErrors
    );
    isValid = dataValidation.isValid;
    hasDefaultAddress = dataValidation.hasDefaultAddress;
    newErrors = { ...newErrors, ...dataValidation.newErrors };
    if (!hasDefaultAddress) {
      isValid = false;
      toast.error("Default address not selected");
    }
    handleAsyncCall(isValid, newErrors);
  };

  const validateZipCode = (address, isValid, addressErrors) => {
    if (!address.state) {
      isValid = false;
      addressErrors.state = "State is required";
    }

    if (address.country_Id === 218) {
      if (!address.zipCode) {
        isValid = false;
        addressErrors.zipCode = "Zip code is required";
      } else if (!zipCodeRegex.test(address.zipCode)) {
        isValid = false;
        addressErrors.zipCode =
          "Zip code should be in the format xxxxx or xxxxx-xxxx";
      }
    } else {
      if (!address.zipCode) {
        isValid = false;
        addressErrors.zipCode = "Zip code is required";

      } else if (address.zipCode && address.zipCode.length < 4) {
        isValid = false;
        addressErrors.zipCode = "Zip code should have a minimum length of 4";
      }
    }

    if (!address.phoneNumber) {
      isValid = false;
      addressErrors.phoneNumber = "Phone Number is required";
    }

    if (!address.city) {
      isValid = false;
      addressErrors.city = "City is required";
    }

    return { address, isValid, addressErrors };
  };

  const validateAddresses = (isValid, hasDefaultAddress, newErrors) => {
    let dataRes = formData.addressModels.forEach((address, index) => {
      let addressErrors = {};


      let zipData = validateZipCode(address, isValid, addressErrors);
      isValid = zipData.isValid;
      addressErrors = { ...addressErrors, ...zipData.addressErrors };

      if (address.isDefault === 1) {
        hasDefaultAddress = true;
      }

      if (validatePhoneNumber(address.phoneNumber)) {

        isValid = false;
        addressErrors.phoneNumber =
          "Phone number should have a minimum of 10 digits";
      }

      if (!address.addressFirst) {
        isValid = false;
        addressErrors.addressFirst = "Address 1 is required";
      }

      if (!address.addressSecond) {
        isValid = false;
        addressErrors.addressSecond = "Address 2 is required";
      }

      newErrors[`addressModels_${index}`] = addressErrors;
    });
    return { isValid, hasDefaultAddress, newErrors, dataRes };
  };


  const handleDefaultAddressChange = (index) => {
    if (defaultAddressIndex !== index) {
      if (
        defaultAddressIndex !== -1 &&
        defaultAddressIndex < formData.addressModels.length
      ) {
        handleChange(defaultAddressIndex, "isDefault", 0);
      }
      setDefaultAddressIndex(index);
      handleChange(index, "isDefault", 1);
    }
  };

  const handleCancel = () => {

    if (props && props?.onHide) {
      props.onHide();
    }
  };

  const fetchAddress = async (zipCode, index) => {
    if (zipCode) {
      let responseAddress = await Promise.resolve(
        dispatch(fetchAddressFromZipCode(zipCode))
      );
      if (responseAddress?.data?.Status === 200) {
        let address = responseAddress?.data?.Entity;
        if (address && address?.Country) {
          let formDataObj = { ...formData };
          let selectedCountry = countries.filter(
            (country) => country.name === address?.Country
          )[0];
          selectedCountry.label = selectedCountry.name;
          selectedCountry.value = selectedCountry.id;
          formDataObj.addressModels[index].country = selectedCountry.name;
          formDataObj.addressModels[index].country_Id = selectedCountry.value;
          formDataObj.addressModels[index].state =
            address.Country === "United States"
              ? USSates.filter((state) => state.StateName === address?.State)[0]
                .StateName
              : address?.State;
          formDataObj.addressModels[index].city =
            address.City || formDataObj.city;
          setFormData(formDataObj);
          setErrors({});
        }
      }
    }
  };

  return (
    <Modal
      {...props}
      size="lg"
      className="topUpModal"
      centered
      backdrop="static"
      ref={modalRef}
      show={showModal}
    >
      <div className="top_modal_header_container">
        <span className="">
          {props?.type === "seller" ? "Add Seller" : "Add Company"}
        </span>
        <div className="modalDeleteWrapper">
          <button
            type="button"
            onClick={() => handleCancel()}
            class="btn-close"
            aria-label="Close"
          ></button>
        </div>
      </div>
      <div className="modal_container_rate_card seller-Modal fontFix">
        {props?.type === "seller" ? (
          <>
            <div className="row modal_input">
              <div className="col-4 formLabel ">
                <span className="input-label fnt-wgt-editmgmt">Seller Name:</span>
              </div>
              <div className="col-8">
                <div class={"select_react"}>
                  <input
                    onChange={(e) => TopUpForm(e.target.value, "sellerName")}
                    className="form-control availableBalanceInput"
                    type="text"
                  />
                  <p className="error dialogError">{errors.sellerName}</p>
                </div>
              </div>
            </div>
            <div className="row modal_input">
              <div className="col-4 formLabel ">
                <span className="input-label fnt-wgt-editmgmt">Seller Code:</span>
              </div>
              <div className="col-8">
                <div class={"select_react"}>
                  <input
                    onChange={(e) => TopUpForm(e.target.value, "sellerCode")}
                    className="form-control availableBalanceInput"
                    type="text"
                  />
                  <p className="error dialogError">{errors.sellerCode}</p>
                </div>
              </div>
            </div>
            {!props?.associatedSeller && (
              <div className="row modal_input">
                <div className="col-4 formLabel">
                  <span className="input-label fnt-wgt-editmgmt">Company:</span>
                </div>
                <div className="col-8">
                  <Select
                    isMulti
                    options={props?.companyList}
                    onChange={(e) =>
                      handleCompanySelected(e, 'companyIds')
                    }
                    className="activeSellerListInput"
                    classNamePrefix="list"
                  />
                  <p className="error dalogError countrytype-error">
                    {errors.companyIds}
                  </p>
                </div>
              </div>
            )}
            <div className="row modal_input">
              <div className="col-4 formLabel ">
                <span className="input-label fnt-wgt-editmgmt">STE Code :</span>
              </div>
              <div className="col-8">
                <div class={"select_react"}>
                  <input
                    onChange={(e) => TopUpForm(e.target.value, "steCode")}
                    className="form-control availableBalanceInput"
                    type="text"
                  />
                  <p className="error dialogError">{errors.steCode}</p>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="row modal_input">
            <div className="col-4 formLabel ">
              <span className="input-label fnt-wgt-editmgmt">Company Name:</span>
            </div>
            <div className="col-8">
              <div class={"select_react"}>
                <input
                  onChange={(e) => TopUpForm(e.target.value, "name")}
                  className="form-control availableBalanceInput"
                  type="text"
                />
                <p className="error dialogError">{errors.name}</p>
              </div>
            </div>
          </div>
        )}

        <div className="row modal_input">
          <div className="col-4 formLabel ">
            <span className="input-label fnt-wgt-editmgmt">Email:</span>
          </div>
          <div className="col-8">
            <div class={"select_react"}>
              <input
                onChange={(e) => TopUpForm(e.target.value, "email")}
                className="form-control availableBalanceInput"
                type="email"
              />
              <p className="error dialogError">{errors.email}</p>
            </div>
          </div>
        </div>
        <div className="row modal_input">
          <div className="col-4 formLabel ">
            <span className="input-label fnt-wgt-editmgmt">Secondary Email:</span>
          </div>
          <div className="col-8">
            <div class={"select_react"}>
              <input
                onChange={(e) => TopUpForm(e.target.value, "secondaryEmailAddress")}
                className="form-control availableBalanceInput"
                type="secondaryEmailAddress"
              />
              <p className="error dialogError">{errors.secondaryEmailAddress}</p>
            </div>
          </div>
        </div>

        {checkPermission(accountsManagement, SUBF_SELLER, permissionAssociatedWarehouse, featureSubmodulePermission, employeePermissions) && (
          <>
            {props?.type === "seller" && (
          <div className="row modal_input">
            <div className="col-4 formLabel ">
              <span className="input-label fnt-wgt-editmgmt">Warehouse:</span>
            </div>
            <div className="col-8">
              <div class={"select_react"}>
                <Select
                  isMulti
                  options={warehouseList}
                  onChange={(e) =>
                    handleCompanySelected(e, 'warehouseIds')
                  }
                  // value={formData?.warehouseIds}
                  className="activeSellerListInput"
                  classNamePrefix="list"
                />
                <p className="error dialogError">{errors.warehouseIds}</p>
              </div>
            </div>
          </div>
            )}
          </>
        )}

        <div className="row modal_input">
          <div className="col-4 formLabel ">
            <span className="input-label fnt-wgt-editmgmt">Notes:</span>
          </div>
          <div className="col-8">
            <div class={"select_react"}>
              <textarea
                onChange={(e) => TopUpForm(e.target.value, "notes")}
                rows={2}
                name="notes"
                value={formData?.notes}
                className="form-control availableBalanceInput confirmPassword"
              />
            </div>
          </div>
        </div>
        <div className="row modal_input fontFix">
          <div className="col-4 formLabel"></div>
          {checkPermission(inventoryManagement, '', '', featureModule, employeePermissions) && (
            <div className="col-8">
              <div class={"select_react"}>
                <input
                  type="checkbox"
                  checked={formData.isInventoryManagementAccess}
                  name="isInventoryManagementAccess"
                  onChange={(e) =>
                    TopUpForm(e.target.checked, "isInventoryManagementAccess")
                  }
                  className="checkBoxField"
                />{" "}
                Inventory Management Access
              </div>
            </div>
          )}
        </div>

        {formData.addressModels.map((address, index) => (
          <div className="addressFields fontFix" key={address?.id}>
            <div className="row modal_input">
              <div className="col-4 ">
                <span className="input-label fnt-wgt-editmgmt">Address {index + 1}-</span>
              </div>
              <div className="col-8">
                <span className="input-label col-4">
                  <input
                    type="radio"
                    id={`defaultAddress_${index}`}
                    onChange={(e) => handleDefaultAddressChange(index)}
                    checked={defaultAddressIndex === index}
                    value="1"
                  />{" "}
                  <label className="default-add-label fnt-wgt-editmgmt" for="html">
                    Default Address
                  </label>
                </span>
              </div>
            </div>

            <div className="row modal_input">
              <div className="col-4 formLabel">
                <span className="input-label fnt-wgt-editmgmt">Country:</span>
              </div>
              <div className="col-8">
                <Select
                  options={countryList}
                  onChange={(value) => handleChange(index, "country_Id", value)}
                  className="activeSellerListInput"
                  // defaultValue={{ label: address.country, value: address.country_Id }}
                  classNamePrefix="list"
                  value={{ label: address.country, value: address.country_Id }}
                />
                {errors[`addressModels_${index}`] &&
                  errors[`addressModels_${index}`].country_Id && (
                    <p className="error dalogError">
                      {errors[`addressModels_${index}`].country_Id}
                    </p>
                  )}
              </div>
            </div>
            <div className="row modal_input">
              <div className="col-4 formLabel ">
                <span className="input-label fnt-wgt-editmgmt">Zip Code:</span>
              </div>
              <div className="col-8">
                <div class={"select_react"}>
                  <input
                    onChange={(e) =>
                      handleChange(index, "zipCode", e.target.value)
                    }
                    onKeyDown={(e) => handleInputValueTypes(e, /^[0-9-]$/)}
                    className="form-control availableBalanceInput"
                    onBlur={(e) => fetchAddress(e.target.value, index)}
                    type="text"
                    value={address.zipCode}
                  />
                </div>
                {errors[`addressModels_${index}`] &&
                  errors[`addressModels_${index}`].zipCode && (
                    <p className="error dalogError countrytype-error">
                      {errors[`addressModels_${index}`].zipCode}
                    </p>
                  )}
              </div>
            </div>
            {address?.country_Id === 218 ? (
              <div className="row modal_input">
                <div className="col-4 formLabel">
                  <span className="input-label fnt-wgt-editmgmt">State:</span>
                </div>
                <div className="col-8">
                  <Select
                    options={stateList}
                    onChange={(value) =>
                      handleChange(index, "state", value.label)
                    }
                    value={{ label: address.state, value: "" }}
                    className="activeSellerListInput"
                    classNamePrefix="list"
                  />
                  {errors[`addressModels_${index}`] &&
                    errors[`addressModels_${index}`].state && (
                      <p className="error dalogError countrytype-error">
                        {errors[`addressModels_${index}`].state}
                      </p>
                    )}
                </div>
              </div>
            ) : (
              <div className="row modal_input">
                <div className="col-4 formLabel">
                  <span className="input-label fnt-wgt-editmgmt">State:</span>
                </div>
                <div className="col-8">
                  <input
                    onChange={(e) =>
                      handleChange(index, "state", e.target.value)
                    }
                    onKeyDown={(e) => handleInputValueTypes(e, /^[A-Za-z]$/)}
                    className="form-control availableBalanceInput"
                    type="text"
                    value={address.state}
                  />
                  {errors[`addressModels_${index}`] &&
                    errors[`addressModels_${index}`].state && (
                      <p className="error dalogError countrytype-error">
                        {errors[`addressModels_${index}`].state}
                      </p>
                    )}
                </div>
              </div>
            )}

            <div className="row modal_input">
              <div className="col-4 formLabel">
                <span className="input-label fnt-wgt-editmgmt">City:</span>
              </div>
              <div className="col-8">
                <input
                  onChange={(e) => handleChange(index, "city", e.target.value)}
                  className="form-control availableBalanceInput"
                  onKeyDown={(e) => handleInputValueTypes(e, /^[A-Za-z]$/)}
                  type="text"
                  value={address.city}
                />
                {errors[`addressModels_${index}`] &&
                  errors[`addressModels_${index}`].city && (
                    <p className="error dalogError countrytype-error">
                      {errors[`addressModels_${index}`].city}
                    </p>
                  )}
              </div>
            </div>
            <div className="row modal_input">
              <div className="col-4 formLabel ">
                <span className="input-label fnt-wgt-editmgmt">Phone Number:</span>
              </div>
              <div className="col-8">
                <div class={"select_react"}>
                  <Input
                    value={address.phoneNumber}
                    name="phoneNumber"
                    onChange={(e) => handleChange(index, "phoneNumber", e)}
                    className={`form-control availableBalanceInput`}
                  />
                  {errors[`addressModels_${index}`] &&
                    errors[`addressModels_${index}`].phoneNumber && (
                      <p className="error dalogError">
                        {errors[`addressModels_${index}`].phoneNumber}
                      </p>
                    )}
                </div>
              </div>
            </div>
            <div className="row modal_input">
              <div className="col-4 formLabel ">
                <span className="input-label fnt-wgt-editmgmt">Secondary Phone Number:</span>
              </div>
              <div className="col-8">
                <div class={"select_react"}>
                  <Input
                    value={address.secondaryPhoneNumber}
                    name="secondaryPhoneNumber"
                    onChange={(e) => handleChange(index, "secondaryPhoneNumber", e)}
                    className={`form-control availableBalanceInput`}
                  />
                  {errors[`addressModels_${index}`] &&
                    errors[`addressModels_${index}`].secondaryPhoneNumber && (
                      <p className="error dalogError">
                        {errors[`addressModels_${index}`].secondaryPhoneNumber}
                      </p>
                    )}
                </div>
              </div>
            </div>

            <div className="row modal_input">
              <div className="col-4 formLabel">
                <span className="input-label fnt-wgt-editmgmt">Address Line 1:</span>
              </div>
              <div className="col-8">
                <input
                  onChange={(e) =>
                    handleChange(index, "addressFirst", e.target.value)
                  }
                  value={address?.addressFirst}
                  className="form-control availableBalanceInput"
                  type="text"
                />
                {errors[`addressModels_${index}`] &&
                  errors[`addressModels_${index}`].addressFirst && (
                    <p className="error dalogError countrytype-error">
                      {errors[`addressModels_${index}`].addressFirst}
                    </p>
                  )}
              </div>
            </div>
            <div className="row modal_input">
              <div className="col-4 formLabel">
                <span className="input-label fnt-wgt-editmgmt">Address Line 2:</span>
              </div>
              <div className="col-8">
                <input
                  value={address?.addressSecond}
                  onChange={(e) =>
                    handleChange(index, "addressSecond", e.target.value)
                  }
                  className="form-control availableBalanceInput"
                  type="text"
                />
                {errors[`addressModels_${index}`] &&
                  errors[`addressModels_${index}`].addressSecond && (
                    <p className="error dalogError countrytype-error">
                      {errors[`addressModels_${index}`].addressSecond}
                    </p>
                  )}
              </div>
            </div>
            <div className="row modal_input">
              <div className="col-4 formLabel">
                <span className="input-label fnt-wgt-editmgmt">Address Line 3:</span>
              </div>
              <div className="col-8">
                <input
                  value={address?.addressThird}
                  onChange={(e) =>
                    handleChange(index, "addressThird", e.target.value)
                  }
                  className="form-control availableBalanceInput"
                  type="text"
                />
              </div>
            </div>
            {formData.addressModels.length > 1 && (
              <button
                className="addplusicon shipment removeParcel  removeAdd-btn"
                onClick={() => removeAddress(index)}
                title="Remove this Address"
              >
                {" "}
                <img alt="" src={minus} />
              </button>
            )}
          </div>
        ))}

        <button
          className="addplusicon shipment"
          title="Add new Address"
          onClick={addAddress}
        >
          {" "}
          <img alt="" className="plusIcon" src={plus} />
        </button>

        <Row className="modaL_footer">
          <div
            className="col-12 noPadding"
            style={{ textAlign: "right" }}
            xs={12}
          >
            <Button
              className="btn cus-seconday-bg-btn cancel-btn"
              onClick={() => handleCancel()}
            >
              Cancel
            </Button>
            <Button
              id="saveTopUpBtn"
              className="btn cus-seconday-bg-btn marginLeft5"
              onClick={(e) => {
                saveTopUpData();
              }}
            >
              Add
            </Button>
          </div>
        </Row>
      </div>
    </Modal>
  );
}
export default AddCompanyModal;
