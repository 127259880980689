import React from "react";
import "../TopUpModal/topUpModal.css";
import Modal from "react-bootstrap/Modal";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import moment from "moment";
import Select from "react-select";
import {
  GET_SINGLE_FUNDS_DATA,
  GET_WALLET_BALANCE,
} from "../../../store/types";
import {
  getSellerWalletDetails,
  AdjustWalletDetails,
  getSellers,
  getSellersByCompanyId,
} from "../../../store/actions/fundManagement";
import DatePicker from "react-datepicker";

function PriceAdjustModal(props) {
  const { fundList, showModal = true } = props;
  const dispatch = useDispatch();
  const [errors, setErrors] = useState({});
  const [sellerList, setSellerList] = useState();
  const [selectedsellerData, setselectedSellerData] = useState();
  const [amount, setAmount] = useState(null);
  const getSellersValue = useSelector(
    (state) => state?.FundManagementReducer?.getSellersDetail?.EntityList
  );
  const sellerWalletBalance = useSelector(
    (state) => state?.FundManagementReducer?.getSellerWalletBalance?.EntityList
  );
  const createdBy = useSelector((state) => state?.auth?.user);

  useEffect(() => {
    setselectedSellerData("");
  }, [amount]);

  useEffect(() => {
    handleCompanySellerDefault();
  }, []);

  const handleCompanySellerDefault = async () => {
    if (fundList?.length && props.activeSellerList.length) {
      let findChecked = fundList.filter(item => item.isChecked);
      if (findChecked.length) {
        getSellersByCompanyId(findChecked[0].companyId)?.then((resp) => {
          console.log('resp----->', resp)
          let data = resp?.data?.EntityList.map((item) => {
            return {
              label: item.sellerName,
              value: item.sellerId,
            };
          });
          setSellerList(data);
          let findInCompanyList = props.activeSellerList.filter(item => item.Id === findChecked[0].companyId)[0] || [];
          let findInSellerList = data?.filter(item => item.value === findChecked[0].sellerId)[0] || [];
          setFormData({
            ...formData,
            topupCompanyId: findInCompanyList,
            topupSellerId: findInSellerList
          })
        })
      }
    }
  }

  const transactionType = [
    {
      label: "Credit",
      value: 4,
    },
    {
      label: "Debit",
      value: 5,
    },
  ];
  const [formData, setFormData] = useState({
    createdBy: createdBy?.Value?.employeeId,
    companyId: createdBy?.Value?.companyId,
    sellerId: createdBy?.Value?.sellerId,
    SellerName: {},
    isActive: true,
    id: selectedsellerData && selectedsellerData?.id,
    amount: 0,
    topupCompanyId: null,
  });
  useEffect(() => {
    if (getSellersValue && getSellersValue.length) {
      let data = getSellersValue.map((item) => {
        return {
          value: item.sellerId,
          label: item.sellerName,
        };
      });
      setSellerList(data);
    } else {
      setSellerList([]);
    }
  }, [getSellersValue, formData]);

  useEffect(() => {
    if (formData?.topupCompanyId?.value) {
      dispatch(getSellers(formData?.topupCompanyId?.Id));
      if (formData?.topupSellerId?.value) {
        dispatch(
          getSellerWalletDetails({
            topupCompanyId: formData?.topupCompanyId?.value,
            topupSellerId: formData?.topupSellerId?.value,
          })
        );
      }
    }
  }, [formData]);

  const TopUpForm = (item, name, sellerdetail) => {
    if (name === "topupCompanyId") {
      dispatch(getSellers(item?.Id));
      setFormData({
        ...formData,
        topupSellerId: null,
        [name]: item,
        availableAmount: null,
      });
    } else {
      setFormData({ ...formData, [name]: item });
    }
    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const handleDateChange = (e, name) => {
    setFormData({
      ...formData,
      [name]: moment(e).format("MM-DD-YYYY"),
    });
    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const saveTopUpData = () => {
    if (
      formData?.topupCompanyId &&
      formData?.amount > 0 &&
      formData?.topupSellerId &&
      formData?.transactionType
    ) {
      dispatch(
        AdjustWalletDetails(
          {
            ...formData,
            createdDate: moment(formData?.createdDate).format("MM-DD-YYYY HH:mm:ss"),
            topupCompanyId: formData?.topupCompanyId?.Id,
            topupCompayId: formData?.topupCompanyId?.Id,
            topupSellerId: formData?.topupSellerId?.value,
            role: createdBy?.Value?.role,
            companySellerId: createdBy?.Value?.companySellerId,
          },
          sellerWalletBalance,
          setAmount,
          createdBy?.Value?.isAdmin,
          props.onHide
        )
      );
    } else {
      if (
        !formData?.SellerName?.name &&
        !formData?.amount >= 0 &&
        !formData?.topupSellerId &&
        !formData?.transactionType
      ) {
        setErrors({
          SellerName: "This is a mandatory field",
          amount: "Amount should not be empty or 0",
          topupSellerId: "This is a mandatory field",
          transactionType: "Please select transaction type",
        });
      } else if (!formData?.SellerName?.name == {} && formData?.amount >= 0) {
        setErrors({
          SellerName: "This is a mandatory field",
        });
      } else if (!formData?.amount && !formData?.topupSellerId?.label) {
        setErrors({
          amount: "Amount should not be empty or 0",
          topupSellerId: "This is a mandatory field",
        });
      } else if (!formData?.amount) {
        setErrors({
          amount: "Amount should not be empty or 0",
        });
      } else if (!formData?.topupSellerId?.label) {
        setErrors({
          topupSellerId: "This is a mandatory field",
        });
      }
    }
  };

  const handleCancel = () => {
    dispatch({
      type: GET_WALLET_BALANCE,
      payload: [],
    });
    dispatch({
      type: GET_SINGLE_FUNDS_DATA,
      payload: [],
    });
    props.onHide();
  };

  return (
    <Modal
      {...props}
      size="lg"
      className="topUpModal"
      backdrop="static"
      centered
      show={showModal}
    >
      <div className="top_modal_header_container">
        <span className="">Price Adjustment</span>
        <div className="modalDeleteWrapper">
          <button
            type="button"
            onClick={() => handleCancel()}
            className="btn-close"
            aria-label="Close"
          ></button>
        </div>
      </div>
      <div className="modal_container_rate_card">
        <div className="row modal_input">
          <div className="col-4 formLabel ">
            <span className="input-label fnt-wgt-editmgmt">Transaction Type:</span>
          </div>
          <div className="col-8">
            <div className={"select_react"}>
              <Select
                options={transactionType}
                onChange={(value) => TopUpForm(value.value, "transactionType")}
                className="activeSellerListInput"
                id="listlistst"
                classNamePrefix={'list'}
              />
            </div>
            <p className="error dialogError"> {errors?.transactionType}</p>
          </div>
        </div>
        <div className="row modal_input">
          <div className="col-4 formLabel ">
            <span className="input-label fnt-wgt-editmgmt">Company Name:</span>
          </div>
          <div className="col-8">
            <div className={"select_react"}>
              <Select
                options={props?.activeSellerList}
                onChange={(value) => TopUpForm(value, "topupCompanyId", "seller")}
                className="activeSellerListInput"
                value={formData.topupCompanyId}
                classNamePrefix={'list1'}
              />
            </div>
            <p className="error dialogError"> {errors?.SellerName}</p>
          </div>
        </div>
        <div className="row modal_input">
          <div className="col-4 formLabel ">
            <span className="input-label fnt-wgt-editmgmt">Seller Name:</span>
          </div>
          <div className="col-8">
            <div className={"select_react"}>
              <Select
                options={sellerList}
                onChange={(value) => TopUpForm(value, "topupSellerId")}
                className="activeSellerListInput"
                isDisabled={!formData?.topupCompanyId}
                value={formData?.topupSellerId}
                id="text6"
                classNamePrefix={'list2'}
              />
            </div>
            <p className="error dialogError"> {errors?.topupSellerId}</p>
          </div>
        </div>
        <div className="row modal_input">
          <div className="col-4 formLabel">
            <span className="input-label fnt-wgt-editmgmt">Available Balance ($):</span>
          </div>
          <div className="col-8">
            <input
              onChange={(e) => TopUpForm(e, "AvailableBalance")}
              disabled
              value={
                sellerWalletBalance?.length &&
                sellerWalletBalance?.[0]?.availableAmount
              }
              className="form-control availableBalanceInput"
              type="text"
            />
            <p className="error dialogError">{errors.Name}</p>
          </div>
        </div>
        <div className="row modal_input">
          <div className="col-4 formLabel">
            <span className="input-label fnt-wgt-editmgmt">Transaction Date:</span>
          </div>
          <div className="col-8">
            <DatePicker
              data-testid={'createdDate'}
              className="form-control createdDate"
              showIcon
              dateFormat="MM-dd-yyyy"
              name="createdDate"
              onChange={(e) => handleDateChange(e, "createdDate")}
              value={formData?.createdDate || moment(new Date()).format("MM-DD-YYYY")}
            />
            <p className="error dialogError">{errors.createdDate}</p>
          </div>
        </div>
        <div className="row modal_input">
          <div className="col-4 formLabel">
            <span className="input-label fnt-wgt-editmgmt">Enter The Amount ($):</span>
          </div>
          <div className="col-8">
            <input
              onChange={(e) => TopUpForm(e.target.value, "amount")}
              className="form-control amountInput"
              min={1}
              onKeyDown={(event) => {
                if (event.key === "-") {
                  event.preventDefault(); // Prevents typing the minus sign
                }
              }}
              type="number"
              id="text2"
            />
            <p className="error dialogError">{errors.amount}</p>
          </div>
        </div>
        <div className="row modal_input">
          <div className="col-4 formLabel">
            <span className="input-label fnt-wgt-editmgmt">Description:</span>
          </div>
          <div className="col-8">
            <textarea
              onChange={(e) => TopUpForm(e.target.value, "description")}
              className="form-control amountInput text-area"
              type="text"
              id="text1"
            />
          </div>
        </div>
        <Row className="modaL_footer">
          <div
            className="col-12 noPadding"
            style={{ textAlign: "right" }}
            xs={12}
          >
            <Button
              className="btn cus-seconday-bg-btn"
              id="dan"
              // variant="danger"
              onClick={() => handleCancel()}
            >
              Cancel
            </Button>
            <Button
              id="saveTopUpBtn"
              className="btn cus-seconday-bg-btn marginLeft5"
              onClick={(e) => {
                saveTopUpData();
              }}
            >
              Adjust
            </Button>
          </div>
        </Row>
      </div>
    </Modal>
  );
}

export default PriceAdjustModal;
